import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import FlatList from '@uz/unitz-components-web/FlatList';
import TeacherItemX from '@uz/unitz-tool-components/TeacherItemX';
import { Row } from 'antd';
import _ from 'lodash';

const View8 = () => {
  return (
    <DIV className="component">
      <FlatList
        as={Row}
        gutter={[0, 16]}
        data={ctx.get('paginationModel.items', [])}
        renderItem={TeacherItemX}
        keyExtractor={(item, index) => `${_.get(item, 'id')}_${index}`}
      />
    </DIV>
  );
};

export default displayName(View8);
