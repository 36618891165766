import React from 'react';
import { bindings, hook } from '@vl/redata';
import _ from 'lodash';
import B2BCourseTemplateModel from '@uz/unitz-models/B2BCourseTemplateModel';
import { notification } from 'antd';

const bindData = bindings({
  component: {
    rules: [
      [
        'data',
        {
          data: {
            form: hook(() => {
              const [isModalVisible, setIsModalVisible] = React.useState(true);
              return {
                isModalVisible,
                showModal: () => {
                  setIsModalVisible(true);
                },
                handleCancel: () => {
                  setIsModalVisible(false);
                },
              };
            }),

            courseTemplateItemInfo: hook((ctx) => {
              const template_id = ctx.get('@item.id');
              return {
                ...ctx.get('@item'),
                dulicateCourseTemplate: async () => {
                  try {
                    const course_template = await B2BCourseTemplateModel.createCourseTemplate({
                      object: {
                        ..._.omit(ctx.get('@item'), [
                          'id',
                          'courses',
                          'course_template_lessons',
                          'link_url',
                          'materials',
                        ]),
                        materials: `{${_.map(ctx.get('@item.materials')).join(',')}}`,
                        link_urls: `{${_.map(ctx.get('@item.link_urls')).join(',')}}`,
                      },
                    });

                    if (course_template) {
                      const course_template_id = _.get(course_template, 'insert_b2b_course_template_one.id', '');
                      const insert_lessons = await B2BCourseTemplateModel.insertLessons({
                        lessons: _.map(ctx.get('@item.course_template_lessons'), (item) => {
                          return {
                            ..._.omit(item, ['materials', 'link_urls']),
                            template_id: course_template_id,
                            materials: `{${_.map(ctx.get('@item.materials')).join(',')}}`,
                            link_urls: `{${_.map(ctx.get('@item.link_urls')).join(',')}}`,
                          };
                        }),
                      });
                      if (insert_lessons) {
                        ctx.apply('paginationModel.onRefresh');
                      } else {
                        notification.error({
                          placement: 'bottomRight',
                          message: ctx.apply('i18n.t', 'ZoomToolError.default'),
                        });
                      }
                    } else {
                      notification.error({
                        placement: 'bottomRight',
                        message: ctx.apply('i18n.t', 'ZoomToolError.default'),
                      });
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },

                deleteCourseTemplate: async () => {
                  try {
                    const payload = {
                      user_id: ctx.apply('currentUserModel.getUserId'),
                      account_id: ctx.get('@item.account_id'),
                      template_id: template_id,
                    };

                    const res = await B2BCourseTemplateModel.deleteCourseTemplate(payload);
                    if (!!res) {
                      await ctx.apply('modalModel.hide');
                      ctx.apply('paginationModel.onRefresh');
                    }
                  } catch (err) {
                    console.log(err);
                  }
                },
              };
            }),
          },
        },
      ],
    ],
  },
});

export default bindData;
