import React from 'react';
import { ctx } from '@vl/redata';
import DIV from '@vl/redata/DIV.macro';
import displayName from '@vl/redata/displayName.macro';
import { Modal, Row, Col, Upload, Divider, Alert } from 'antd';
import FormProvider from '@uz/unitz-components-web/FormProvider';
import { Form, FormItem } from 'formik-antd';
import gstyles from '@vl/gstyles';
import styled from 'styled-components';
import './modal.scss';
import Button from '@uz/unitz-components-web/Button';
import CustomInput from '@uz/unitz-components-web/CustomInput';
import CustomTable from '@uz/unitz-components-web/CustomTable';
import _ from 'lodash';
import { renderPropsComposer } from '@vl/mod-utils/PropsComposer';
import { Link } from '@uz/unitz-components-web/Link';

const layout = {
  labelAlign: 'left',
  labelCol: { xs: { span: 24, offset: 0 }, md: { span: 6 } },
  wrapperCol: {
    xs: { span: 24, offset: 0 },
  },
};

const AttachmentInputContainer = styled(CustomInput)`
  .ant-upload-list-item {
    .ant-upload-list-item-progress {
      padding-right: 32px;
    }
  }
`;
const AttachmentInput = styled(Upload.Dragger)`
  .ant-upload.ant-upload-btn {
    padding: 0px;
  }
`;

const View8 = () => {
  return (
    <DIV className="component">
      <Modal
        title={''}
        visible={ctx.get('import_form.isModalVisible')}
        wrapClassName={'my-modal'}
        className="rounded-lg overflow-hidden"
        maskStyle={{ background: gstyles.colors.black300 }}
        destroyOnClose
        centered
        width={1248}
        closable
        closeIcon={
          <div className="mt-4 ml-4" onClick={ctx.get('import_form.handleCancel')}>
            {gstyles.icons({ name: 'close', size: 24, fill: gstyles.colors.sub })}
          </div>
        }
        onCancel={() => ctx.apply('modalModel.hide')}
        afterClose={() => ctx.apply('modalModel.hide')}
        footer={[
          <Row justify="end" key="footer">
            <Col span={6}>
              <Button
                type="primary"
                block
                onClick={
                  !!ctx.get('import_form.dirty')
                    ? ctx.get('import_form.handleSubmit')
                    : ctx.get('import_form.handleCancel')
                }
                disabled={!ctx.get('import_form.canSubmit')}
                loading={ctx.get('import_form.isSubmitting')}
              >
                {ctx.apply('i18n.t', 'ZoomToolCourse.Action.done')}
              </Button>
            </Col>
          </Row>,
        ]}
      >
        {ctx.get('import_form.isModalVisible') && (
          <FormProvider form={ctx.get('import_form')}>
            <Form {...layout} className="import-form" id="add-form">
              <Divider />
              <div className="text-xl font-semibold text-main mb-6">
                {ctx.apply('i18n.t', 'ZoomToolMember.Excel.import_teacher')}
              </div>
              <div className="text-sm font-semibold text-sub">
                {ctx.apply('i18n.t', 'ZoomToolMember.Excel.description_1')}
                <span>
                  <Link className="text-brandb500 font-semibold" external target="_blank" to="/samples/sample.xls">
                    {ctx.apply('i18n.t', 'ZoomToolMember.Excel.sample_file')}
                  </Link>
                </span>
              </div>
              <div className="text-sm font-semibold text-sub">
                {ctx.apply('i18n.t', 'ZoomToolMember.Excel.description_2')}
              </div>
              <div className="text-sm font-semibold text-sub">
                {ctx.apply('i18n.t', 'ZoomToolMember.Excel.description_3')}
              </div>
              <div className="flex items-center py-4">
                <div>{gstyles.icons({ name: 'info', size: 16, fill: gstyles.colors.sub })}</div>
                <div className="pl-2 text-sm font-semibold text-sub">
                  {ctx.apply('i18n.t', 'ZoomToolMember.Excel.support')}
                </div>
              </div>

              <FormItem name="file">
                <AttachmentInputContainer>
                  <AttachmentInput
                    accept=".xls,.xlsx"
                    {...ctx.apply('import_form.getUploaderProps', { fieldName: 'file' })}
                  >
                    <div className="flex flex-row items-center justify-center py-4">
                      <Button name="ink">{ctx.apply('i18n.t', 'Course.Info.Action.select_files')}</Button>
                      <div className="flex flex-row items-center justify-center ml-4">
                        <span>{gstyles.icons({ name: 'cloud-upload', size: 24, fill: gstyles.colors.sub })}</span>
                        <div className="text-base font-normal text-main pl-2">
                          {ctx.apply('i18n.t', 'Course.Info.Action.drop_files_here')}
                        </div>
                      </div>
                    </div>
                  </AttachmentInput>
                </AttachmentInputContainer>
              </FormItem>

              {renderPropsComposer(
                {
                  matcher: (props) => props === 'done',
                  render: () => (
                    <div className="mb-3">
                      {ctx.get('import_form.errors.import') === 'excel error' ? (
                        <Alert
                          closable
                          style={{ borderColor: gstyles.colors.red.lighter, borderRadius: 8 }}
                          closeIcon={gstyles.icons({ name: 'close', fill: gstyles.colors.red500, size: 24 })}
                          message={
                            <>
                              <div className="py-2 text-red500 font-semibold">
                                {ctx.apply('i18n.t', 'ZoomToolError.Member.import.intro')}
                              </div>
                              <div>
                                {_.map(ctx.apply('i18n.t', 'ZoomToolError.Member.import.errors'), (error) => (
                                  <div className="text-red500 font-semibold">{error}</div>
                                ))}
                              </div>
                            </>
                          }
                          type="error"
                        />
                      ) : (
                        <Alert
                          closable
                          style={{ borderColor: gstyles.colors.brandb.lighter, borderRadius: 8 }}
                          closeIcon={gstyles.icons({ name: 'close', fill: gstyles.colors.brandb500, size: 24 })}
                          message={
                            <div className="py-2 text-brandb500 font-semibold">
                              {ctx.apply('i18n.t', 'ZoomToolMember.Excel.Notifications.member_success')}
                            </div>
                          }
                          type="info"
                        />
                      )}
                    </div>
                  ),
                },
                () => null
              )(ctx.get('import_form.values.file[0].status'))}

              {!!ctx.get('import_form.values.members.length') && !!ctx.get('import_form.values.file.length') ? (
                <>
                  <CustomTable
                    columns={ctx.get('tableColumn.columns')}
                    dataSource={ctx.get('tableColumn.dataSource', [])}
                    rowKey={(rec) => _.get(rec, 'id')}
                    loading={ctx.get('import_form.values.file[0].status') === 'uploading'}
                    // pagination={ctx.get('tableColumn.pagination')}
                    // onChange={ctx.get('paginationModel.onChange')}
                    size="middle"
                  />
                </>
              ) : null}
            </Form>
          </FormProvider>
        )}
      </Modal>
    </DIV>
  );
};

export default displayName(View8);
